import React from "react"
import styled from "styled-components"
import PortfolioItem from "../PortfolioItem/PortfolioItem"
import { breakpoints } from "../../utils/styles"

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const PortfolioContainer = ({ portfolio }) => {
  return (
    <RootContainer>
      {portfolio.map((item, index) => {
        return <PortfolioItem item={item} key={index} />
      })}
    </RootContainer>
  )
}

export default PortfolioContainer
