import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import SectionContainer from "../shared/SectionContainer"
import { breakpoints } from "../../utils/styles"
import SectionTitle from "../shared/SectionTitle"
import SectionSubtitle from "../shared/SectionSubtitle"

const RootItemContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ItemContainer = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    flex-basis: 33%;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-basis: 25%;
  }
`

const ImgContainer = styled.div`
  width: 100%;
  max-width: 125px;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 150px;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 225px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 250px;
  }
`

const ItemTextContainer = styled.div`
  margin-top: 1rem;
  h4 {
    font-family: var(--header-font);
    text-transform: uppercase;
    font-size: 1.25rem;
    margin: 0;
    line-height: 1.5;
  }

  p {
    line-height: 1.5;
    margin: 0;
    font-size: 1.125rem;
    font-family: var(--regular-font);
  }

  a {
    color: #000;

    :hover {
      text-decoration: none;
    }
  }
`

const PortfolioSections = ({ sections }) => {
  const renderHtml = image_text => {
    return (
      <ItemTextContainer
        dangerouslySetInnerHTML={{ __html: image_text.html }}
      />
    )
  }

  return (
    <>
      {sections.map(section => {
        const {
          section_background,
          section_title,
          section_subtitle,
        } = section.primary
        return (
          <SectionContainer
            background={section_background}
            key={section_title.text}
          >
            <SectionTitle>{section_title.text}</SectionTitle>
            <SectionSubtitle>{section_subtitle.text}</SectionSubtitle>
            <RootItemContainer>
              {section.items.map((item, index) => {
                const { image, image_text } = item
                return (
                  <ItemContainer key={index}>
                    <ImgContainer>
                      <Img fluid={image.localFile.childImageSharp.fluid} />
                    </ImgContainer>
                    {image_text && renderHtml(image_text)}
                  </ItemContainer>
                )
              })}
            </RootItemContainer>
          </SectionContainer>
        )
      })}
    </>
  )
}

export default PortfolioSections
