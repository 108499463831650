import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import PortfolioContainer from "../components/PortfolioContainer/PortfolioContainer"
import PortfolioSections from "../components/PortfolioSections/PortfolioSections"
import LetMeHelp from "../components/LetMeHelp/LetMeHelp"
import JoinSection from "../components/JoinSection/JoinSection"

const PortfolioPage = props => {
  const {
    data: {
      allPrismicPortfolioBodyPortfolio,
      allPrismicPortfolioBodySectionNoText,
    },
    location,
  } = props
  return (
    <Layout path={location.pathname}>
      <SEO title="Portfolio" />
      <PortfolioContainer portfolio={allPrismicPortfolioBodyPortfolio.nodes} />
      <PortfolioSections
        sections={allPrismicPortfolioBodySectionNoText.nodes}
      />
      <LetMeHelp />
      <JoinSection />
    </Layout>
  )
}

export default PortfolioPage

export const portfolioPageQuery = graphql`
  query portfolioPageQuery {
    allPrismicPortfolioBodyPortfolio {
      nodes {
        primary {
          background_color
          link {
            url
          }
          logo_image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          portfolio_title {
            text
          }
          subtitle {
            text
          }
          ribbon_text {
            text
          }
        }
      }
    }
    allPrismicPortfolioBodySectionNoText {
      nodes {
        primary {
          section_title {
            text
          }
          section_subtitle {
            text
          }
          section_background
        }
        items {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          image_text {
            html
          }
        }
      }
    }
  }
`
