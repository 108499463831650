import React from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import { breakpoints } from "../../utils/styles"
import Button from "../Button/Button"
import SectionTitle from "../shared/SectionTitle"
import { MdChevronRight } from "react-icons/md"

const Root = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 2fr 5fr;
    grid-template-rows: 1fr;
  }
`

const MainTitle = styled.h4`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--header-font);
    display: initial;
    font-size: 3rem;
    padding: 4rem;
    width: 100%;
    max-width: 350px;
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 4rem;
  }
`

const SecondContainer = styled.div`
  background: #000;
  padding: 4rem 2rem;
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    padding: 6rem 4rem;
    text-align: left;
  }
`

const TaglineContainer = styled.div`
  margin-top: 1rem;
  p {
    margin: 0;
    font-size: 1.375rem;
    color: #999999;
    line-height: 1.5;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 1.75rem;
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  font-family: var(--subheader-font);
  padding: 0.75rem 1rem;
  background: linear-gradient(to right, #fff, #fff) no-repeat;
  background-size: 0 100%;

  svg {
    height: 20px;
    width: auto;
  }

  :hover {
    color: #000;
    border-color: #fff;
  }
`

const LetMeHelp = () => {
  const {
    prismicLetMeHelp: {
      data: { main_title, secondary_title, tagline },
    },
  } = useStaticQuery(QUERY)
  return (
    <Root>
      <MainTitle>{main_title.text}</MainTitle>
      <SecondContainer>
        <SectionTitle color="#fff">{secondary_title.text}</SectionTitle>
        <TaglineContainer dangerouslySetInnerHTML={{ __html: tagline.html }} />
        <StyledButton forwardedAs={Link} to="/contact" color="#fff">
          Contact me
          <MdChevronRight />
        </StyledButton>
      </SecondContainer>
    </Root>
  )
}

export default LetMeHelp

const QUERY = graphql`
  query learnQuery {
    prismicLetMeHelp {
      data {
        main_title {
          text
        }
        secondary_title {
          text
        }
        tagline {
          html
        }
      }
    }
  }
`
